<template>
 <div >
    <!--篩選區塊-->
    <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->

    <!--
    <el-row>
      <div style="float: right; font-size: 15px; color: blue; solid;">取樣間隔(單位:分鐘) </div>  
      </el-row>
      <el-row>
          <div class="block">
            <el-slider :min="60" :max="1440" input-size="mini" :step="60"
                       v-model.lazy="listQuery.sampleRate"  show-input  >
            </el-slider>
          </div>
    </el-row>
    -->
   
      <el-row>
      <div style="float: right;">
        
        <el-select v-model="controllerId" :placeholder="$t('ChargingStationS')"
           clearable size="small" class="filter-item-mid" width="140px">
          <el-option v-for="item in controllerOption" :key="item.controlId"
                    :label="item.stationName + '(' + $t('Controller')+ ':' + item.controlId + ')'" :value="item.controlId" />                
        </el-select>

                                          <!--替換:選擇設備(電表)-->
        <el-select v-model="deviceIds" :placeholder=" $t('Device') + '(' + $t('Meter') + ')' + '【' + $t('MultipleChoice') + '】' " 
           clearable size="small" class="filter-item-mid" width="140px" multiple collapse-tags>
          <el-option v-for="item in devOption" :key="item.devId"
                    :label="item.devName + ' (id:' + item.devId + ')'" :value="item.devId" />
        </el-select>

        <!--
        <el-date-picker
            v-model="listQuery.updatedate"
            size="small"
            class="filter-item"
            type="date"
            value-format="yyyy-MM-dd" 
            :placeholder="$t('Date')" >     
        </el-date-picker>  -->  
        <!--
         <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="listQuery.startdate"
            :end-placeholder="listQuery.enddate">  
        </el-date-picker> -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startDate">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.endDate">
        </el-date-picker>           

        <el-select v-model="listQuery.sampleRate" :placeholder="$t('SampleTime')" 
           clearable size="small" class="filter-item-mid" width="100px">
          <el-option v-for="item in sampleIntervalOption" :key="item.value"
                    :label="item.hour" :value="item.value" />
        </el-select>

        <el-button style="float: right; margin-right:50px;" 
          type="primary" 
          size="small" 
          icon="el-icon-search" 
          @click="queryButton()" >{{$t('Query')}}
        </el-button>        
        <!--
        <el-input size="small" v-model="listQuery.staionId" placeholder="查詢" class="filter-item" clearable />      -->
   
      </div>
    </el-row>
    <el-row>



      <el-button style="float: left; margin-right:50px;" 
                 type="primary" 
                 size="small" 
                 icon="el-icon-s-data" 
                 v-if="(list!=undefined&&list!=null&&list.length>0)&&(listAll==undefined||listAll==null||listAll.length<=0)"
                 @click="openFullScreen()" >產生電量及碳排放圖並匯出報表
      </el-button>


      <div style="float: left;">
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <!--<el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;">{{$t('DownloadAllDataXlsx')}}</el-button> -->
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('energy','/energy/timeofuse','EXPORT','匯出用電紀錄報表  xlsx')">.xlsx</el-button>        
      </excel-xlsx>
      </div>  

      <div style="float: left;">
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xls'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <!--<el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;">{{$t('DownloadAllDataXls')}}</el-button> -->
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('energy','/energy/timeofuse','EXPORT','匯出用電紀錄報表  xls')">.xls</el-button>           
      </excel-xlsx>
      </div>

      <div style="float: left;">  
      <download-excel 
         :data="listAll" :fields="json_fields" :name="filenameAll" type="csv"
         v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"> 
         <!--<el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;">{{$t('DownloadAllDataCsv')}}</el-button> -->
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('energy','/energy/timeofuse','EXPORT','匯出用電紀錄報表  csv')">.csv</el-button>            
      </download-excel>    
      </div>
         
      <span v-if="( listSum!=undefined&&listSum!=null ) && ( listSum.total!=0 || listSum.flowFee!=0 || listSum.carbon!=0 )" style="font-weight: bold; font-size: 1.2em; float: right;">
          總用電量: {{listSum.total}} 度，總流動電費: $ {{listSum.flowFee}} 元，總碳排放量: {{listSum.carbon}} CO2e
      </span>
      


    </el-row>
    <!-- table -->
    <el-table
      size="mini" stripe 
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '20px'}"
      @sort-change="tableSortChange" :cell-class-name="tableColumnClass"
    >
      <el-table-column align="center" prop="time"          label="時間範圍" sortable /> <!--替換:時間範圍-->       
      <el-table-column align="center" prop="usage"         label="區間電量" sortable color="Indigo"/> <!--替換:區間電量-->
      <el-table-column align="center" prop="maxDemand"     label="最大需量" sortable />    <!--替換:區間需量-->      
      <el-table-column align="center" prop="powerFactor"   label="功率因子" sortable />  <!--替換:功率因子-->
      <el-table-column align="center" prop="maxDemandTime" min-width="90px" label="最大需量發生時間"/>            <!--替換:最大需量發生時間-->
      <el-table-column align="center" prop="peakUsage"     label="尖峰度數" sortable/>     <!--替換:尖峰度數-->
      <el-table-column align="center" prop="halfPeakUsage" label="半尖峰度數" sortable /><!--替換:半尖峰度數-->
      <el-table-column align="center" prop="satHalfPeakUsage" min-width="100px" label="周六半尖峰度數" sortable /><!--替換:周六半尖峰度數-->
      <el-table-column align="center" prop="offUsage"      min-width="70px" label="離峰度數"/>  <!--替換:離峰度數-->
      <el-table-column align="center" prop="flowFee"       label="流動電費" sortable/><!--替換:流動電費-->
      <el-table-column align="center" prop="carbon"        min-width="60px" label="碳排量" sortable /><!--替換:碳排量-->
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" 
              :size.sync="listQuery.size" @pagination="getList" style="margin: 15px;"/>

      <div v-if="chartDataExist" style="width:100%; height:280px; left; display: flex;" >
      <div  style="flex:1;">
      <v-chart 
          :option="usageChart"
          :theme="chartsTheme"
          :update-options="{notMerge: true}"
          style="width: 100%; height:100%;"
      />
      </div>
      <div style="flex:1;">
      <!--第二個圖渲染完畢後呼叫loadingClose來關閉loading加載圖-->
      <v-chart 
          :option="carbonChart"
          :theme="chartsTheme"
          :update-options="{notMerge: true}"
          style="width: 100%; height:100%;"
          @finished="loadingClose"
      />
      </div>
    </div>
   
  </div>
</template>

<style>
.el-table .color-column {
  background:'#6A6AFF' ;
}
</style>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
const authUrl = main.authUrl();
const refreshTokenUrl = authUrl + "refreshToken";
const refreshTokenInterval = 10*60*1000;     // 10分鐘

export default {
    name: 'timeofuse',
    components: {
        Pagination,
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth     : 0,
            total           : 0,
            controllerId    : undefined,
            controllerOption: undefined,
            deviceIds       : [], //因應複選 故要用陣列
            devOption       : undefined,   
            sampleIntervalOption:
                             [ {hour:"15分鐘(15min)",value:15},{hour:"30分鐘(30min)",value:30},
                               {hour:"1小時(1 hour)",value:60},{hour:"1整日(1 Day)",value:1440} ],
            list            : [],
            listSum         : undefined,
            listAll         : undefined,            
            json_fields     : {
              "time"         : "time",    //CSV只允許英文
              "usage"        : "usage",
              "maxDemand"    : "maxDemand",
              "powerFactor"  : "powerFactor",
              "maxDemandTime": "maxDemandTime",
              "peakUsage"    : "peakUsage",
              "halfPeakUsage": "halfPeakUsage",
              "satHalfPeakUsage": "satHalfPeakUsage",
              "offUsage"     : "offUsage",
              "flowFee"      : "flowFee",
              "carbon"       : "carbon"
            },
            json_meta: [
              [ {
                  " key "    : " charset ",
                  " value "  : " utf- 8 "
                } ]
            ],
            xlsxColumns      : [
                {
                    label: "time",
                    field: "time",
                },
                {
                    label: "usage",
                    field: "usage",
                },
                {
                    label: "maxDemand",
                    field: "maxDemand",
                },
                {
                    label: "powerFactor",
                    field: "powerFactor",
                },
                {
                    label: "maxDemandTime",
                    field: "maxDemandTime",
                },
                {
                    label: "peakUsage",
                    field: "peakUsage",
                },
                {
                    label: "halfPeakUsage",
                    field: "halfPeakUsage",
                },
                {
                    label: "satHalfPeakUsage",
                    field: "satHalfPeakUsage",
                },
                {
                    label: "offUsage",
                    field: "offUsage",
                },
                {
                    label: "flowFee",
                    field: "flowFee",
                },
                {
                    label: "carbon",
                    field: "carbon",
                },
                ],
            /* 匯出檔名 */
            filenameCsv    : this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReport" + ".xls",
            filenameAll    : this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReportAll" + ".xls",
            filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReportAll" ,
            listLoading    : false,
            listQuery: { 
                //serviceId: this.$store.state.currentAccount.id,  
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                deviceIds  :  undefined,  
                sampleRate :  undefined, //undefined
                startDate  :  this.$moment().subtract(10, "days").format("YYYY-MM-DD"),//10天前,
                endDate    :  this.$moment().subtract(0, "days").format("YYYY-MM-DD"),//0天前
                page       : 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size       : 15,
            },
            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            dateRange      : undefined,
            dateStart      : undefined,
            dateEnd        : undefined,
            dialogSettingVisible: false,

            timer          : undefined,
            loading        : undefined,
            size           : undefined,
            chartDataExist : false, // 是否有圖表資料
            xAxisData      : [],    // x軸 日期時間
            yAxisData      : [],    // y軸  
            y2AxisData     : [],    // y軸2             
        };
    },
  async created() {
    console.log("[list]=",this.list);
    console.log("[listAll]=",this.listAll);
    this.dialogWidth = this.setDialogWidth();
    this.getStationAndControllerByServiceId();
    /* this.resetPage(this.getList); */

    //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    //定時刷新
    this.id_setInterval = window.setInterval(async function () {
      try{
        const response = await refreshToken(refreshTokenUrl);
        console.log("auth/refreshToken success! , id_setInternal="+this.id_setInterval);
        sessionStorage.setItem('csrf_token', response.data.data);
      }catch (error){
        console.log("delete company error");
        console.log(error);
      }
    }, refreshTokenInterval);
  },
  beforeDestroy() {
    
    clearTimeout( this.timer );

    //在切換Component之前，將refresh token的setInterval timeout 給移除
    if(this.id_setInterval !== null)
    {
      console.log('beforeUnmount is called, clear the timout id='+this.id_setInterval);
      window.clearInterval(this.id_setInterval);
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },    
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
    '$i18n.locale': function(val) {
      console.log('Role組件重載前')
      this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
      console.log('Role組件重載完成')
      },
    /* ----------------------------------------------------------------------------- */    
    'controllerId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.deviceIds = undefined;
      //this.resetPage(this.getList);
      this.listAll= [];
      this.listSum= null;
      this.deviceIds = [];
      this.devOption=null;
      /* 若充電站(控制器)有被選擇,則才會找該充電站(控制器)所屬的設備電表,沒被選擇則stationId為0 */
      if( val != null && val != undefined && val > 0 ) {
        console.log("GET:/api/v1/billingquery/deviceidList?controlerId="+val);
        this.axios.get("/api/v1/billingquery/deviceidList?controlerId="+val).then(
            res => {
            console.log("Response:deviceidList [res.data]",res.data);
            this.devOption=res.data;
            }
            );
      }
     },   
    'deviceIds': function (val) {
      this.listQuery.page = 0;
      //this.resetPage(this.getList);
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= [];
      this.listSum= null;
      this.chartDataExist = false;
    },
    'listQuery.sampleRate': function () {
      this.listQuery.page = 0
      //this.resetPage(this.getList);
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= [];
      this.listSum= null;
      this.chartDataExist = false;
    },
        /*
    'listQuery.updatedate': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)      
      this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },  
    }, */  
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      //this.resetPage(this.getList);
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= [];
      this.listSum= null;
      this.chartDataExist = false;
    },*/
    dateStart: function(val) {
      if (val) {
        this.listQuery.startDate = this.dateStart
      } else {
        this.listQuery.startDate = undefined
      }
      //this.resetPage(this.getList);
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= [];
      this.listSum= null;
      this.chartDataExist = false;
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.endDate = this.dateEnd
      } else {
        this.listQuery.endDate = undefined
      }
      //this.resetPage(this.getList);
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= [];
      this.listSum= null;
      this.chartDataExist = false;
    },            
  },

  computed: {
    usageChart() {
        let _this = this  
        let option = {     
          tooltip: {
            trigger: "axis",              
          }, 
          legend: {              //顯示該圖表資訊      
            data: ['區間電量'],  
            //bottom :1            //文字置底
          },        
          xAxis: {        
            type: 'category',  
            data: _this.xAxisData
          },
          yAxis: {
            type: 'value',
            name: '度',
            nameTextStyle:{
              color:'Indigo', 
              //fontSize:12,  
            },
            data: _this.yAxisData        
          },                  
          series: [
            {
              data: _this.yAxisData, //圖表的數據
              type: 'line',      //圖表類型        
              symbol: 'none',
              name:'區間電量',  
              color:'Indigo',
              stack: true,      
            },                         
          ]
        }
        return option
      },
    carbonChart() {
        let _this = this  
        let option = {     
          tooltip: {
            trigger: "axis",              
          }, 
          legend: {              //顯示該圖表資訊      
            data: ['碳排放量'],  
            //bottom :1            //文字置底
          },        
          xAxis: {        
            type: 'category',  
            data: _this.xAxisData
          },
          yAxis: {
            type: 'value',
            name: 'CO2e',
            nameTextStyle:{
              color:'DarkSlateGray', 
              //fontSize:12,  
            },             
            data: _this.y2AxisData        
          },                  
          series: [
            {
              data: _this.y2AxisData, //圖表的數據
              type: 'bar',      //圖表類型        
              symbol: 'none',
              name:'碳排放量',  
              color:'DarkSlateGray',
              stack: true,      
            },                         
          ]
        }
        return option
      },      
  },
  methods: {
    tableColumnClass({row,column,rowIndex,columnIndex}) {
       if(columnIndex === 1) { return 'color-column'; }
       else  return '';
    },
    /* 產生loading圖示 */
    openFullScreen(){
      this.loading = this.$loading(
        {
          lock: true,
          text: '圖表產生中',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.7)',
        }
      );
      this.timer = setTimeout(
        ()=>{this.getchart();},
        1500
      );  
    },
    queryButton(){
      if(this.deviceIds == null || this.deviceIds == undefined || this.deviceIds.length == 0 )
      {
        return this.$message({ message: '未選擇設備(電表)', type: 'warning' })
      }
      if(this.listQuery.sampleRate == null || this.listQuery.sampleRate == undefined )
      {
        return this.$message({ message: '未選擇取樣率', type: 'warning' })
      }    
      this.getList();
    },
    getStationAndControllerByServiceId() { 
      console.log("GET:/api/v1/billingquery/controleridList?serviceId="+this.listQuery.serviceId);
      this.axios.get("/api/v1/billingquery/controleridList?serviceId="+this.listQuery.serviceId).then(
          res => {
          console.log("Response:/api/v1/billingquery/controleridList [res.data]",res.data);
          this.controllerOption=res.data; 
          }
         );
    },       
    async getList() {

      if( (this.listQuery.startDate==null) || (this.listQuery.endDate==null) ) {
        this.listQuery.startDate = "2024-02-01";
        this.listQuery.endDate   = "2024-02-28";
      }

      this.listLoading = true;
      this.listQuery.deviceIds = this.deviceIds.toString(); //把[xx,yy,zz]轉成"xx,yy,zz"
      console.log("watch:listQuery.deviceIds="+this.listQuery.deviceIds);
      console.log("GET://api/v1/billingquery/httpget2"+JSON.stringify(this.listQuery));

      await this.axios.get('/api/v1/billingquery/httpget2', { params:  this.listQuery }).then( 
        res=> {
                console.log("Response:/api/v1/billingquery/httpget2",res.data)
                this.list = res.data.content
                this.total = res.data.totalElements        
              },
        error => {
                return alert("忘了選擇日期!!");
              }
        )  

      console.log("/api/v1/billingquery/httpget2total"+JSON.stringify(this.listQuery));
      await this.axios.get('/api/v1/billingquery/httpget2total', { params:  this.listQuery }).then(
        res=> {
                console.log("Response://api/v1/billingquery/httpget2total",res.data)
                this.listSum = res.data
              },
        error => {
                return alert("忘了選擇日期!!");
              }              
        )
      
      this.listLoading = false;

    },
    async getchart() {

      this.size          = this.listQuery.size
      this.listQuery.page=0
      this.listQuery.size=10000
      this.chartDataExist = false
      this.chartloading = true
      this.xAxisData= []
      this.yAxisData= []
      this.y2AxisData= []      
      
      await this.axios.get('/api/v1/billingquery/httpget2', {params: this.listQuery}).then(res=> {

        this.listAll = res.data.content

        res.data.content.forEach(e =>
        {
          this.chartDataExist = true
          this.xAxisData.push(e.time )
          this.yAxisData.push(e.usage )                    
          this.y2AxisData.push(e.carbon )
        })
        this.chartloading = false
      })
      
      this.listQuery.size=this.size
      this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReport" + ".xls"
      this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReportAll" + ".xls"
      this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_TimeOfUseReportAll" 

    },
    /* 關閉loading圖示 */
    loadingClose(){
      this.loading.close();
    },
    handleRowClick(row, column, event) {
    },
  },
}

</script>
